/* Template Name: Helix SYS - Responsive Bootstrap 5.3 Landing Page Template
   Author: Themesbrand
   Version: 3.0.0
   Created: Jan 2019
   File Description: Main Css file of the template
*/

/**********************
01.General
02.Menu
03.Components
04.Helper
05.Loader
06.Hero
07 Why Choose Us
08.Features
09.Pricing
10.Contact
11.Footer
12.Modal
***********************/

@import "variables";
@import "theme-light";
@import "theme-dark";
@import "general";
@import "menu";
@import "components";
@import "switcher";
@import "helper";
@import "loader";
@import "hero";
@import "why-choose-us";
@import "features";
@import "pricing";
@import "contact";
@import "footer";
@import "modal";
